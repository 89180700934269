import Cookies from 'js-cookie'

const token = '';
const code = '';
const time = new Date().getTime();

const TokenKey = 'wxToken'

export default {
    token,   //用户token信息
    code,    //code信息
    time     //token更新时间
}

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

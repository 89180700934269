import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/tiaozhuan',
    name: 'tiaozhuan',
    component: () => import(/* webpackChunkName: "about" */ '../views/dome/index.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/myheard',
    name: 'myheard',
    component: () => import(/* webpackChunkName: "about" */ '../components/Head1.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/bangding',
    name: 'bangding',
    component: () => import(/* webpackChunkName: "about" */ '../views/bangding.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/anaerobic',
    name: 'anaerobic',
    component: () => import(/* webpackChunkName: "about" */ '../views/anaerobic.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/anaerobic_1',
    name: 'anaerobic_1',
    component: () => import(/* webpackChunkName: "about" */ '../views/anaerobic_1.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/mobileindex2',
    name: 'mobileindex2',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile_index2.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/mobiletiaozhuan/:page/',
    name: 'mobiletiaozhuan',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobiletiaozhuan.vue')
  },
  {
    path: '/mobile/:page/',
    name: 'mobile',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/mobile2/:page/',
    name: 'mobile',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile2.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/pretreatment',
    name: 'pretreatment',
    component: () => import(/* webpackChunkName: "about" */ '../views/pretreatment.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/pretreatment1',
    name: 'pretreatment1',
    component: () => import(/* webpackChunkName: "about" */ '../views/pretreatment1.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/solidLiquid1',
    name: 'solidLiquid1',
    component: () => import(/* webpackChunkName: "about" */ '../views/solidLiquid1.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/solidLiquid2',
    name: 'solidLiquid2',
    component: () => import(/* webpackChunkName: "about" */ '../views/solidLiquid2.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/dehydrate',
    name: 'dehydrate',
    component: () => import(/* webpackChunkName: "about" */ '../views/dehydrate.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/aombr',
    name: 'aombr',
    component: () => import(/* webpackChunkName: "about" */ '../views/aombr.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/aombr1',
    name: 'aombr1',
    component: () => import(/* webpackChunkName: "about" */ '../views/aombr1.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/denitrification',
    name: 'denitrification',
    component: () => import(/* webpackChunkName: "about" */ '../views/denitrification.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/aerobic',
    name: 'aerobic',
    component: () => import(/* webpackChunkName: "about" */ '../views/aerobic.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/plate',
    name: 'plate',
    component: () => import(/* webpackChunkName: "about" */ '../views/plate.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/methane',
    name: 'methane',
    component: () => import(/* webpackChunkName: "about" */ '../views/methane.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/chang',
    name: 'chang',
    component: () => import(/* webpackChunkName: "about" */ '../views/quanchanggongyi.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/anaonestage',
    name: 'anaonestage',
    component: () => import(/* webpackChunkName: "about" */ '../views/anaonestage.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/mbrthree',
    name: 'mbrthree',
    component: () => import(/* webpackChunkName: "about" */ '../views/mbrthree.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/boiler',
    name: 'boiler',
    component: () => import(/* webpackChunkName: "about" */ '../views/boiler.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/within',
    name: 'within',
    component: () => import(/* webpackChunkName: "about" */ '../views/within.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/fireControl',
    name: 'fireControl',
    component: () => import(/* webpackChunkName: "about" */ '../views/fireControl.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/workmanship',
    name: 'workmanship',
    component: () => import(/* webpackChunkName: "about" */ '../views/workmanship.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/sadWater', 
    name: 'sadWater',
    component: () => import(/* webpackChunkName: "about" */ '../views/sadWater1.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/gps', 
    name: 'gps',
    component: () => import(/* webpackChunkName: "about" */ '../views/gps.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/crt', 
    name: 'crt',
    component: () => import(/* webpackChunkName: "about" */ '../views/crt.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/qiti', 
    name: 'qiti',
    component: () => import(/* webpackChunkName: "about" */ '../views/qiti.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/sad2', 
    name: 'sad2',
    component: () => import(/* webpackChunkName: "about" */ '../views/sad2.vue'),
    meta: {
      keepAlive: false,
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
